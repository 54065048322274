import React from 'react'
import './Landing.css'

import twitter from '../imgs/twit.svg'
import telegram from '../imgs/tel.svg'

const duelLogo = require('../imgs/RustDuelsSpin.gif')
const logo = require('../imgs/LOGO.png')
const discord = require('../imgs/discord-mark-white.png')

function Landing() {
    return (
        <div className='landing-container'>
            <div className='landing-header'>
                <div className='landing-name'>
                    <img src={logo} className='header-logo'/>
                    <h1>
                        RUST DUELS
                    </h1>
                </div>
                <div className='landing-links'>
                    <a href='https://discord.gg/tokenduels' target='_blank' rel='noopener noreferrer'>
                        <h1>
                            [DISCORD]
                        </h1>
                    </a>
                    <a href='https://telegram.org' target='_blank' rel='noopener noreferrer'>
                        <h1>
                            [TELEGRAM]
                        </h1>
                    </a>
                    <a href='https://x.com/RustDuels' target='_blank' rel='noopener noreferrer'>
                        <h1>
                            [TWITTER]
                        </h1>
                    </a>
                </div>
            </div>
            <div className='landing-contents'>
                <h1>
                    RUST DUELS
                </h1>
                <img src={duelLogo} className='contents-logo'/>
                <h2>
                    COMING SOON
                </h2>
                <div className='socials-container'>
                    <div className='social-box'>
                        <a href='https://discord.gg/tokenduels' target='_blank' rel='noopener noreferrer'>
                            <img src={discord} className='social-box-logo'/>
                        </a>
                    </div>
                    <div className='social-box'>
                        <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
                            <img src={twitter} className='social-box-logo'/>
                        </a>
                    </div>
                    <div className='social-box'>
                        <a href='https://x.com/RustDuels' target='_blank' rel='noopener noreferrer'>
                            <img src={telegram} className='social-box-logo'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Landing