// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #11191F;
  margin: 0;

}

.h1 .h2 .h3 {
  margin: 0;
  padding: 0;
}
.p-highlight {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
  width: 100%;
  height: 200%;
  opacity: 0.5;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,yBAAyB;EACzB,SAAS;;AAEX;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,6BAA6B;EAC7B,UAAU;EACV,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".App {\n  width: 100vw;\n  height: 100vh;\n  box-sizing: border-box;\n  overflow: hidden;\n  background-color: #11191F;\n  margin: 0;\n\n}\n\n.h1 .h2 .h3 {\n  margin: 0;\n  padding: 0;\n}\n.p-highlight {\n  position: absolute;\n  top: -100%;\n  left: 50%;\n  transform: translate(-50%, 0);\n  z-index: 0;\n  width: 100%;\n  height: 200%;\n  opacity: 0.5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
