import './App.css';
import Landing from './components/Landing';
const purpleHighlight = require('./imgs/PurpleElipse.png')

function App() {
  return (
    <div className="App">
      <img className='p-highlight' src={purpleHighlight} />

      <Landing/>

    </div>
  );
}

export default App;
